import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto jws`}</strong>{` -- sign and verify data using JSON Web Signature (JWS)`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto jws <subcommand> [arguments] [global-flags] [subcommand-flags]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p>{`JSON Web Signature (JWS) represents content secured with digital signatures or
Message Authentication Codes (MACs) using JSON-based data structures.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Create a signed JWS of a message using an Ed25519 private JWK (with line
breaks for display purposes only):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo -n message | step crypto jws sign --key ed25519.priv.json
eyJhbGciOiJFZERTQSIsImtpZCI6IjlxaVFZUFU3SHJTOXBYdXpYYzc1WGctMXc5c0JGM2lXVld2cDhieE5oc28ifQ
.
bWVzc2FnZQ
.
ZlJvznC3iE4zhwmnCL74UtHXEUs6pb62vf38GFBLbFMKnIFiOGpAFcNv3es-WvPHYfyIIClAjwCfe505gxz5BQ
`}</code></pre>
    <p>{`Verify and display the message using the public key:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo $TOKEN | step crypto jws verify --key ed25519.pub.json
message
`}</code></pre>
    <p>{`Verify and display a JSON representation of the token, the message is base64
encoded in the payload:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo $TOKEN | step crypto jws verify --key ed25519.pub.json --json
{
  "header": {
    "alg": "EdDSA",
    "kid": "9qiQYPU7HrS9pXuzXc75Xg-1w9sBF3iWVWvp8bxNhso"
  },
  "payload": "bWVzc2FnZQ",
  "signature": "ZlJvznC3iE4zhwmnCL74UtHXEUs6pb62vf38GFBLbFMKnIFiOGpAFcNv3es-WvPHYfyIIClAjwCfe505gxz5BQ"
}
`}</code></pre>
    <p>{`Inspect the content of the JWS without verifying it:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo $TOKEN | step crypto jws inspect --insecure
message

$ echo $TOKEN | step crypto jws inspect --insecure --json
{
  "header": {
    "alg": "EdDSA",
    "kid": "9qiQYPU7HrS9pXuzXc75Xg-1w9sBF3iWVWvp8bxNhso"
  },
  "payload": "bWVzc2FnZQ",
  "signature": "ZlJvznC3iE4zhwmnCL74UtHXEUs6pb62vf38GFBLbFMKnIFiOGpAFcNv3es-WvPHYfyIIClAjwCfe505gxz5BQ"
}
`}</code></pre>
    <p>{`Using a JSON message using an P-256 curve and adding the content type json:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo -n {"dns":"https://dns.example.com"} | step crypto jws sign --key p256.priv.json --cty json
eyJhbGciOiJFUzI1NiIsImN0eSI6Impzb24iLCJraWQiOiJWOTNBLVloN0JodzFXMkUwaWdGY2l2aUp6WDRQWFBzd29WZ3JpZWhtOUNvIn0
.
eyJkbnMiOiJodHRwczovL2Rucy5leGFtcGxlLmNvbSJ9
.
ZI8q75r3PCXeu-Tubw7bHiDGxloPpAHV2hNfEp9N4WM2r3Wsk5uFhAkBTVIqryPtxmAgfRHGnE3hj-3Dp9nZmA

$ echo $TOKEN | step crypto jws verify --key p256.pub.json
{"dns":"https://dns.example.com"}

$ echo $TOKEN | step crypto jws verify --key p256.pub.json --json
{
  "header": {
    "alg": "ES256",
    "cty": "json",
    "kid": "V93A-Yh7Bhw1W2E0igFciviJzX4PXPswoVgriehm9Co"
  },
  "payload": "eyJkbnMiOiJodHRwczovL2Rucy5leGFtcGxlLmNvbSJ9",
  "signature": "ZI8q75r3PCXeu-Tubw7bHiDGxloPpAHV2hNfEp9N4WM2r3Wsk5uFhAkBTVIqryPtxmAgfRHGnE3hj-3Dp9nZmA"
}
`}</code></pre>
    <h2>{`Commands`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "sign/"
              }}>{`sign`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`create a signed JWS data structure`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "inspect/"
              }}>{`inspect`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`return the decoded JWS without verification`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td"><a parentName="strong" {...{
                "href": "verify/"
              }}>{`verify`}</a></strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`verify a signed JWS data structure and return the payload`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      